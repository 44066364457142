import React from "react";
import Link from "next/link";
import LocationSvg from "/public/images/icons/location.svg";
import EnvelopeSvg from "/public/images/icons/envelope.svg";
import PhoneSvg from "/public/images/icons/phone.svg";
import LogoSvg from "./sealText.svg"; //different from the normal logo; gets its color from the page theme

import css from "./index.module.scss";

export default function Footer() {
  return (
    <footer className={`section ${css.footer}`}>
      <div className={`sectionInner ${css.sectionInner}`}>
        <div className={css.top}>
          <div className={css.contactInfo}>
            <div className={css.headline}>Contact Us</div>
            <div className={css.locations}>
              <div className={css.location}>
                <div className={css.icon}><LocationSvg/></div>
                <div className={css.key}>8605 Santa Monica Blvd, Ste 93568</div>
                <div className={css.value}>Los Angeles, California 90069</div>
              </div>
              <div className={css.location}>
                <div className={css.icon}><LocationSvg/></div>
                <div className={css.key}>113 Cherry Street</div>
                <div className={css.value}>Seattle, Washington 98104</div>
              </div>
              <div className={css.location}>
                <div className={css.icon}><EnvelopeSvg/></div>
                <div className={css.key}>Drop us a line at:</div>
                <div className={css.value}>
                  <a className="custom" href="mailto:hello@deviatelabs.com">hello@deviatelabs.com</a>
                </div>
              </div>
              <div className={css.location}>
                <div className={css.icon}><PhoneSvg/></div>
                <div className={css.key}>Call us at:</div>
                <div className={css.value}>
                  <a className="custom" aria-label="18887338609" href="tel:+18887338609">+1 (888) 733-8609</a>
                </div>
              </div>
            </div>
            <div className={css.socialLinks}>
              <a className={`custom ${css.socialLink}`} aria-label="Facebook link" href="https://www.facebook.com/deviatelabs" rel="noopener">
                <svg width="100%" height="100%" aria-hidden="true" focusable="false" className="svg-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path fill="#ffffff" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                </svg>
              </a>
              <a className={`custom ${css.socialIcon}`} aria-label="LinkedIn link" href="https://www.linkedin.com/company/deviatelabs" rel="noopener">
                <svg width="100%" height="100%" aria-hidden="true" focusable="false" className="svg-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#ffffff" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                </svg>
              </a>
              <a className={`custom ${css.socialIcon}`} aria-label="Deviate Tracking link" href="https://deviatetracking.com" rel="noopener">
                <svg viewBox="0 0 406.957 406.963" version="1.1" width="406.957" height="406.963" xmlns="http://www.w3.org/2000/svg">
                  <path clipPath="none" d="M172.893 0a29.185 29.185 0 00-29.18 29.187 29.185 29.185 0 0029.18 29.187h58.374a29.185 29.185 0 0029.18-29.187A29.185 29.185 0 00231.268 0zm16.68 84.538a29.185 29.185 0 00-29.188 29.18 29.185 29.185 0 0029.187 29.188h25.016a29.185 29.185 0 0029.187-29.187 29.185 29.185 0 00-29.187-29.181zM29.186 142.753A29.185 29.185 0 000 171.934v58.374a29.185 29.185 0 0029.187 29.181 29.185 29.185 0 0029.18-29.18v-58.375a29.185 29.185 0 00-29.18-29.18zm348.59 0a29.185 29.185 0 00-29.188 29.181v58.374a29.185 29.185 0 0029.187 29.181 29.185 29.185 0 0029.181-29.18v-58.375a29.185 29.185 0 00-29.18-29.18zM113.48 159.426A29.185 29.185 0 0084.3 188.613v25.016a29.185 29.185 0 0029.18 29.187 29.185 29.185 0 0029.188-29.187v-25.016a29.185 29.185 0 00-29.187-29.187zm181.834 0a29.185 29.185 0 00-29.181 29.187v25.016a29.185 29.185 0 0029.18 29.187 29.185 29.185 0 0029.188-29.187v-25.016a29.185 29.185 0 00-29.187-29.187zm-95.073 12.57a29.185 29.185 0 00-27.355 29.125 29.187 29.187 0 0058.374 0 29.185 29.185 0 00-31.02-29.126zm-10.67 95.524a29.185 29.185 0 00-29.187 29.187 29.185 29.185 0 0029.187 29.181h25.016a29.185 29.185 0 0029.187-29.18 29.185 29.185 0 00-29.187-29.188zm-16.679 81.075a29.185 29.185 0 00-29.18 29.181 29.185 29.185 0 0029.18 29.187h58.374a29.185 29.185 0 0029.18-29.187 29.185 29.185 0 00-29.18-29.18z" fill="#fff" fillOpacity="1"/>
                </svg>
              </a>
            </div>
          </div>


          <div className={css.navLinks}>
            <div className={css.headline}>Growth Marketing</div>
            <Link href="/contact"><a className="custom">Contact</a></Link>
            <Link href="/about"><a className="custom">About</a></Link>
            <Link href="/services"><a className="custom">Services</a></Link>
            <Link href="/book"><a className="custom">Book</a></Link>
            <Link href="/ventures"><a className="custom">Ventures</a></Link>
          </div>
          <div className={css.navLinks}>
            <div className={css.headline}>Services</div>
            <Link href="/services/b2b-saas-growth-marketing"><a className="custom">B2B SaaS Growth Marketing</a></Link>
            <Link href="/services/content-marketing"><a className="custom">Content Marketing</a></Link>
            <Link href="/growth-marketing-agency"><a className="custom">Growth Marketing</a></Link>
            <Link href="/services/stunt-marketing"><a className="custom">Stunt Marketing</a></Link>
            <Link href="/services/sonic-branding"><a className="custom">Sonic Branding</a></Link>
            <Link href="/resources/what-is-an-automated-sales-process-asptm-and-how-you-can-use-it-to-quickly-grow-your-business/"><a className="custom">What is the ASP?</a></Link>
          </div>
          <div className={css.navLinks}>
            <div className={css.headline}>Miscellaneous</div>
            <Link href="/resources/categories/case-studies"><a className="custom">Case Studies</a></Link>
            <Link href="/resources"><a className="custom">Resources</a></Link>
            <Link href="/faq"><a className="custom">FAQ</a></Link>
            <Link href="/resources/the-era-of-machine-learning"><a className="custom">Machine Learning</a></Link>
            <Link href="/resources/resource-tools"><a className="custom">Growth Hacking Tools</a></Link>
          </div>
        </div>

        <div className={css.logoSeparator}>
          <LogoSvg/>
        </div>
        <div className={css.miscellaneous}>
          <div>Copyright © {(new Date()).getFullYear()}, Deviate Labs, LLC</div>
          <div>
            <Link href="/legal"><a className="custom">Privacy &amp; Terms</a></Link>, <Link href="/legal/editorial-policy"><a className="custom">Editorial Policy</a></Link>

          </div>
        </div>
      </div>
    </footer>
  );
}

