import React, { useState } from "react";
import Link from "next/link";
import BurgerSvg from "./burger.svg";
import css from "./index.module.scss";
import LogoSvg from "/public/images/logos/deviatelabs/sealText.svg";
import { useEffect } from "react";
import gsap from "scripts/gsap/gsap-core.js";
import MorphSVGPlugin from "scripts/gsap/MorphSVGPlugin";
import Button from "@/components/button";
import EnvelopeSvg from "/public/images/icons/envelope.svg";
import PhoneSvg from "/public/images/icons/phone.svg";

export default function Header({minimal = false}) {
  const [showNavTl, setShowNavTl] = useState(gsap.timeline({paused: true, reversed: true}));
  const [burgerTl, setBurgerTl] = useState(gsap.timeline({paused: true, reversed: true}));

  //set up the nav open/close animations
  useEffect(() => {
    gsap.registerPlugin(MorphSVGPlugin);
    showNavTl.clear();
    burgerTl.clear();

    showNavTl.set(`.${css.fullscreenNav}`, {
      pointerEvents: "none",
      visibility: "visible",
    });

    showNavTl.fromTo(`.${css.fullscreenItem}`, {
      scaleX: 0,
      transformOrigin: "center right",
    }, {
      scaleX: 1,
      duration: 0.25,
      ease: "sine.inOut",
      stagger: {
        amount: 0.6,
        from: "end",
      },
    }, "<");

    showNavTl.set(`.${css.fullscreenNav}`, {
      pointerEvents: "all",
    }, "<5%");

    burgerTl.fromTo(`.${css.hamburger} .burger`, {
      morphSVG: `.${css.hamburger} .burger`,
    }, {
      morphSVG: `.${css.hamburger} .close`,
      duration: 0.25,
      ease: "sine.inOut",
    });
  }, [showNavTl, burgerTl]);

  function toggleFullscreenNav(){
    showNavTl.reversed(!showNavTl.reversed());
    showNavTl.resume();

    burgerTl.reversed(!burgerTl.reversed());
    burgerTl.resume();
  }


  return (
    <nav className={css.dlNav}>
      <div className={css.main}>
        <Link href="/" >
          <a className={`custom ${css.logo}`} aria-label="Deviate Labs Logo">
            <LogoSvg/>
          </a>
        </Link>
        <div className={css.right}>
          <div className={css.buttonWrapper}>
            <a className={`custom ${css.link}`} href="tel:18887338609"><PhoneSvg/>+1 (888) 733-8609</a>
            <a className={`custom ${css.link}`} href="mailto:hello@deviatelabs.com"><EnvelopeSvg/>hello@deviatelabs.com</a>
            <Button href="/contact" label="Contact Us" ghost/>
          </div>
          <div className={css.hamburger} onClick={toggleFullscreenNav}>
            <BurgerSvg/>
          </div>
        </div>
      </div>
      <div className={`initialInvis ${css.fullscreenNav}`}>
        <Link href="/" >
          <a className={`custom ${css.fullscreenItem} ${css.purple}`}>
            <div className={css.itemLine} style={{width: "33px"}}></div>
            <div className={css.itemTitle}>Home</div>
            <div className={css.itemSubtitle}>The creative, resourceful, high-leverage Growth Hacking Consulting Agency.</div>
          </a>
        </Link>
        <Link href="/about">
          <a className={`custom ${css.fullscreenItem} ${css.cyan}`}>
            <div className={css.itemLine} style={{width: "44px"}}></div>
            <div className={css.itemTitle}>About</div>
            <div className={css.itemSubtitle}>Learn more about the marketing minds that drive our innovation.</div>
          </a>
        </Link>
        <Link href="/services">
          <a className={`custom ${css.fullscreenItem} ${css.green}`}>
            <div className={css.itemLine} style={{width: "23px"}}></div>
            <div className={css.itemTitle}>Services</div>
            <div className={css.itemSubtitle}>We offer a full suite of services designed to accelerate your growth.</div>
          </a>
        </Link>
        <Link href="/book">
          <a className={`custom ${css.fullscreenItem} ${css.red}`}>
            <div className={css.itemLine} style={{width: "61px"}}></div>
            <div className={css.itemTitle}>Book</div>
            <div className={css.itemSubtitle}>The co-founders literally wrote the book on Growth Hacking.</div>
          </a>
        </Link>
        <Link href="/ventures">
          <a className={`custom ${css.fullscreenItem} ${css.pink}`}>
            <div className={css.itemLine} style={{width: "39px"}}></div>
            <div className={css.itemTitle}>Ventures</div>
            <div className={css.itemSubtitle}>Our independent investment arm and startup studio.</div>
          </a>
        </Link>
        <Link href="/contact">
          <a className={`custom ${css.fullscreenItem} ${css.yellow}`}>
            <div className={css.itemLine} style={{width: "23px"}}></div>
            <div className={css.itemTitle}>Contact</div>
            <div className={css.itemSubtitle}>Reach out with any questions or requests for a potential engagement.</div>
          </a>
        </Link>
      </div>
    </nav>
  );
}
