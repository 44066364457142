import Head from "next/head";
import React from "react";
import Header from "/sections/header/index.jsx";
import Footer from "/sections/footer/index.jsx";

export default function GenericLayout({title, mainClassName = "", description, schema = null, shareImageUrl = null, color = "purple", children}) {
  return (
    <div id="root" style={{
      "--color": `var(--${color})`,
      "--colorHue": `var(--${color}Hue)`,
      "--colorHsl": `var(--${color}Hsl)`,
    }} >
      <Head>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <meta property="og:description" content={description}/>
        <meta property="og:title" content={title}/>
        {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}

        {shareImageUrl && (
          <meta property="og:image" content={shareImageUrl}/>
        )}
      </Head>
      <Header />
      <main className={mainClassName}>{children}</main>
      <Footer/>
    </div>
  );
}
