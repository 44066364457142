import Link from "next/link";
import React, {forwardRef} from "react";
import css from "./index.module.scss";

export default forwardRef(({
  light = false,
  href = "",
  onClick = () => {},
  label = "",
  className = "",
  target = "_self",
  grayscale = false,
  color = null,
  large = false,
  ghost = false,
}, ref) => {
  let thisClassName = `${className} ${css.deviateButton}`;
  if (light){
    thisClassName += ` ${css.light}`;
  }
  if (ghost){
    thisClassName += ` ${css.ghost}`;
  }
  if (grayscale){
    thisClassName += ` ${css.grayscale}`;
  }
  if (large){
    thisClassName += ` ${css.large}`;
  }

  let style = {};
  if (color){
    style["--color"] = `var(--${color})`;
  }

  if (href){
    return <Link href={href} ref={ref}>
      <a className={`custom ${thisClassName}`} onClick={onClick} style={style}>
        <span>{label}</span>
        <svg viewBox="0 0 230 61" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <g fill="none" fillRule="evenodd">
            <path vectorEffect="non-scaling-stroke" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" d="M40 2h188v15M228 32v27h-50M168 59h-32M126 59h-4.69m-8.56 0H2V2h28.086"/>
          </g>
        </svg>
      </a>
    </Link>;
  }
  return <button className={thisClassName} ref={ref} onClick={onClick} style={style}>
    <span>{label}</span>
    <svg viewBox="0 0 230 61" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <g fill="none" fillRule="evenodd">
        <path vectorEffect="non-scaling-stroke" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" d="M40 2h188v15M228 32v27h-50M168 59h-32M126 59h-4.69m-8.56 0H2V2h28.086"/>
      </g>
    </svg>
  </button>;
});
